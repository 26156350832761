div {
  html,
  body {
    margin: 0;
    padding: 0;
  }

  *,
  *::after,
  *::before {
    box-sizing: border-box;
  }

  body {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #3fe8b3;
    color: #3fe8b3;
    height: 100vh;
    text-rendering: optimizeLegibility;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif,
      'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
  }

  h1 {
    font-size: 2rem;
    text-align: center;
    text-transform: uppercase;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.15), 0 6px 2px 0 rgba(0, 0, 0, 0.1);
  }

  img {
    border-radius: 5px;
    height: auto;
    width: 10rem;
  }

  small {
    display: block;
  }

  a {
    color: #343078;
    text-decoration: none;
  }
}
.nav-pills {
  justify-content: flex-end;
  .nav-link {
    border-radius: 0.25rem 0 0 0.25rem !important;
  }
  .nav-item {
    min-width: 80px;
  }
}
.add-unit-btn {
  background-color: #579e37;
  color: #ffffff;
  border-radius: 5px 0 0 5px;
  border-right: none;
  margin-left: 6px;
  padding-left: 6px;
  justify-content: center;
  margin-bottom: 1rem;
  .fa-plus {
    color: rgba(255, 255, 255, 0.9);
  }
}

.add-unit-btn:hover {
  background-color: #539235;
  .fa-plus {
    color: rgba(255, 255, 255, 1);
  }
}

@media (max-width: 768px) {
  .nav-pills {
    justify-content: flex-start;
    .nav-link {
      border-radius: 0.25rem !important;
    }
    .nav-item {
      min-width: 75px;
    }
  }
  .add-unit-btn {
    width: 5rem;
    border-radius: 5px;
    margin-left: 0;
  }
  .add-unit-icon {
    padding-left: 9px;
  }
}
// .nav-pills .nav-item {
//   width: 100%;
// }
// .nav-pills .nav-link:active {
//   background-color: rgb(25, 180, 45);
// }
