.drop-container {
  padding: 15px;
  background: #fff;
  border-radius: 6px;
  width: 100%;
  box-shadow: 1px 2px 20px hsla(0, 0%, 4%, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px dashed #c0c4c7;
}
p {
  font-size: 16px;
  font-weight: 400;
  color: #c0c4c7;
}
.upload-button {
  display: inline-block;
  border: none;
  outline: none;
  cursor: pointer;
  color: #5754a3;
}
.upload-button input {
  display: none;
}
.dropzone {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border-radius: 5px;
  background: white;
  margin: 10px 0;
}
.dropzone.hovering {
  border: 2px solid #f16624;
  color: #dadada !important;
}
progress::-webkit-progress-value {
  transition: width 0.1s ease;
}
.preview-zone {
  width: 100%;
}
