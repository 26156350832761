.select-box {
  display: flex;
  justify-content: flex-start;
  .wrapper {
    max-height: 40px;
    select {
      width: 250px;
    }
  }
  img {
    width: auto;
    max-height: 350px;
  }
}
