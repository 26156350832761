@font-face {
  font-family: 'SVN';
  src: url('./assets/fonts/SVN-COOKIES.ttf');
}
@font-face {
  font-family: 'Comic Sans Bold';
  src: url('./assets/fonts/Comic-Sans-Bold.ttf');
}
@font-face {
  font-family: 'Arial Round MT Bold';
  src: url('./assets/fonts/Arial-Round-MT-Bold.ttf');
}
@font-face {
  font-family: 'Quicksand';
  src: url('./assets/fonts/Quicksand-SemiBold.ttf');
}
@font-face {
  font-family: 'Quicksand-Bold';
  src: url('./assets/fonts/Quicksand-Bold.ttf');
}
@font-face {
  font-family: 'digital';
  src: url('./assets/fonts/digital.ttf');
}
@font-face {
  font-family: 'digital-mono';
  src: url('./assets/fonts/digital-mono.ttf');
}
@font-face {
  font-family: 'SVN-Trebuchets';
  src: url('./assets/fonts/SVN-Trebuchets.ttf');
}
@font-face {
  font-family: 'SVN-Russell';
  src: url('./assets/fonts/SVN-Russell.ttf');
}
@font-face {
  font-family: 'UTM Androgyne';
  src: url('./assets/fonts/UTM Androgyne.ttf');
}
@font-face {
  font-family: 'HeyComic';
  src: url('./assets/fonts/Hey Comic.ttf');
}
@font-face {
  font-family: 'CHRONICAPRO-MEDIUM';
  src: url('./assets/fonts/CHRONICAPRO-MEDIUM.ttf');
}
@font-face {
  font-family: 'Inter';
  src: url('./assets/fonts/Inter.ttf');
}
@font-face {
  font-family: 'Inter-Bold';
  src: url('./assets/fonts/Inter-Bold.ttf');
}
@font-face {
  font-family: 'Nunito-ExtraBold';
  src: url('./assets/fonts/Nunito-ExtraBold.ttf');
}
@font-face {
  font-family: 'font_tieu_hoc';
  src: url('./assets/fonts/font_tieu_hoc.ttf');
}
@font-face {
  font-family: 'Sancreek';
  src: url('./assets/fonts/Sancreek-Regular.ttf');
}
body {
  margin: 0;
  padding: 0;
  font-family: 'Quicksand' !important;
}

b {
  font-family: 'Quicksand-Bold' !important;
}

#board-game {
  font-family: 'Comic Sans Bold' !important;
}

#board-game {
  padding: 0;
  margin: 0;
  height: 100vh;
  overflow: hidden;
}

.input-box-container {
  font-size: 45px;
  color: #fe910e;
  font-weight: bold;
}

.input-box-container-lg {
  font-size: 150px;
}
// .render-katex {
//   font-size: 40px;
// }
.input-box {
  color: #4c4c99;
  font-family: 'Comic Sans Bold';
  font-size: 45px;
  max-width: 0.8em;
  font-weight: bold;
  border: 0px solid #bcbec0;
  padding: 0 1px;
  border-radius: 0rem !important;
  border-bottom: 2px solid;
  border-bottom-color: #4c4c99;
  background-color: unset;
  outline: none;
}

.input-box-lg {
  // text-align: center;
  color: #2b7993;
  font-family: 'Comic Sans Bold';
  font-size: 150px;
  max-width: 0.8em;
  font-weight: bold;
  border: 0px solid #bcbec0;
  padding: 0 1px;
  border-radius: 0rem !important;
  border-bottom: 2px solid;
  border-bottom-color: transparent;
  background-color: unset;
  outline: none;
  text-transform: uppercase;
}

.look-and-write-text-container {
  padding: 20px;
  padding-bottom: 25px;
}

.pointer-events-none {
  pointer-events: none !important;
}

.desc-team {
  font-size: 50px;
  color: #6974b5;
  border: none;
  min-width: 320px;
  max-width: 390px;
  text-align: center;
  padding: 5px;
  outline: none;
  font-family: 'Comic Sans Bold';
  background-color: rgba(0, 0, 0, 0);
}

ol {
  column-count: 4;
  column-gap: 30px;
}

/* Split the screen in half */
.split {
  height: 100%;
  width: 50%;
  position: fixed;
  z-index: 1;
  top: 0;
  overflow-x: hidden;
  padding-top: 20px;
}

/* Control the left side */
.left {
  left: 0;
  background-color: #5cb85c;
}

/* Control the right side */
.right {
  right: 0;
  background-color: #5bc0de;
}

/* If you want the content centered horizontally and vertically */
.centered {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}
.menu-Game {
  text-align: center;
  background-image: url('./assets/images/menu-game.png');
  background-size: cover;
  z-index: 99999;
  .restart-icon {
    margin-left: 35px;
    cursor: pointer;
    background-image: url('./assets/images/menu-game-restart-icon.png');
    background-size: cover;
  }
  .play-icon {
    cursor: pointer;
    margin-left: 35px;
    background-image: url('./assets/images/menu-game-play-icon.png');
    background-size: cover;
  }
}
.menu-icon-show {
  cursor: pointer;
  background-size: cover;
  background-image: url('./assets/images/menu-icon-show.png');
}

#fill {
  cursor: url('./assets/images/cursor-fill.png'), pointer !important;
}
#draw {
  cursor: url('./assets/images/cursor-draw.png'), pointer !important;
}

// Phần khung
// #root {
//   background-color: #cacaef;
//   background-image: url('/background.png');
// }

// #board-game {
//   padding: 0;
//   margin: 5px 0;
//   height: calc(100vh - 10px);

//   canvas {
//     border: 5px solid #ffffff;
//     border-radius: 50px;
//   }
// }

// @media (max-width: 767px) {
//   #board-game {
//     canvas {
//       border-radius: 20px;
//     }
//   }
// }
