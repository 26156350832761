.error_warning {
  font-size: 13px;
  height: 1000px;
  overflow: auto;
}
.card-text {
  span {
    cursor: pointer;
  }
  span:hover {
    color: #007bff;
  }
}
.card-header {
  b {
    cursor: pointer;
  }
  b:hover {
    color: #e4e4e4;
  }
}