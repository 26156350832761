// .modal-header {
//   .title-modal {
//     box-shadow: none;
//   }
// }
// .modal-body {
//   select {
//     min-height: 40px;
//   }
// }
@keyframes ldio-cnruza7t4zc-1 {
  0% {
    top: 36px;
    height: 128px;
  }
  50% {
    top: 60px;
    height: 80px;
  }
  100% {
    top: 60px;
    height: 80px;
  }
}
@keyframes ldio-cnruza7t4zc-2 {
  0% {
    top: 41.99999999999999px;
    height: 116.00000000000001px;
  }
  50% {
    top: 60px;
    height: 80px;
  }
  100% {
    top: 60px;
    height: 80px;
  }
}
@keyframes ldio-cnruza7t4zc-3 {
  0% {
    top: 48px;
    height: 104px;
  }
  50% {
    top: 60px;
    height: 80px;
  }
  100% {
    top: 60px;
    height: 80px;
  }
}
.ldio-cnruza7t4zc div {
  position: absolute;
  width: 30px;
}
.ldio-cnruza7t4zc div:nth-child(1) {
  left: 35px;
  background: #85a2b6;
  animation: ldio-cnruza7t4zc-1 1s cubic-bezier(0, 0.5, 0.5, 1) infinite;
  animation-delay: -0.2s;
}
.ldio-cnruza7t4zc div:nth-child(2) {
  left: 85px;
  background: #bbcedd;
  animation: ldio-cnruza7t4zc-2 1s cubic-bezier(0, 0.5, 0.5, 1) infinite;
  animation-delay: -0.1s;
}
.ldio-cnruza7t4zc div:nth-child(3) {
  left: 135px;
  background: #dce4eb;
  animation: ldio-cnruza7t4zc-3 1s cubic-bezier(0, 0.5, 0.5, 1) infinite;
  animation-delay: undefineds;
}

.loadingio-spinner-pulse-dvkh3f5tq {
  width: 200px;
  height: 200px;
  display: inline-block;
  overflow: hidden;
  background: rgba(0, 0, 0, 0);
}
.ldio-cnruza7t4zc {
  width: 100%;
  height: 100%;
  position: relative;
  transform: translateZ(0) scale(1);
  backface-visibility: hidden;
  transform-origin: 0 0; /* see note above */
}
.ldio-cnruza7t4zc div {
  box-sizing: content-box;
}
