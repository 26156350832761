form {
  /*! CSS Used from: Embedded */
  * {
    margin: 0;
    padding: 0;
  }
  img {
    border: 0;
  }
  a {
    text-decoration: none;
  }
  ul {
    list-style: none;
  }
  p {
    -webkit-margin-before: 0;
    -webkit-margin-after: 0;
    -webkit-margin-start: 0;
    -webkit-margin-end: 0;
    text-rendering: geometricPrecision;
  }
  input[type='text'] {
    -webkit-appearance: none;
  }
  input,
  h3,
  h6 {
    font: 14px/18px Helvetica, Arial, 'DejaVu Sans', 'Liberation Sans', Freesans, sans-serif;
    color: #333;
    outline: none;
    zoom: 1;
  }
  #search-site {
    width: 50%;
    margin: 10px 10px 0 10px;
    background: #fff;
    position: relative;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
  }

  .wrap-suggestion {
    display: block;
    border: 1px solid #e2e2e2;
    background: #fff;
    position: absolute;

    width: 369px;
    margin-top: 29px;
    top: 45px;
    z-index: 9;
  }
  .wrap-suggestion:after,
  .wrap-suggestion:before {
    bottom: 100%;
    left: 80px;
    border: solid transparent;
    content: ' ';
    height: 0;
    width: 0;
    position: absolute;
  }
  .wrap-suggestion:after {
    border-color: rgba(255, 255, 255, 0);
    border-bottom-color: #fff;
    border-width: 8px;
    margin-left: -8px;
  }
  .wrap-suggestion:before {
    border-color: rgba(218, 218, 218, 0);
    border-bottom-color: #dadada;
    border-width: 9px;
    margin-left: -9px;
  }

  header #search-site {
    height: 30px;
    margin-top: 5px;
    width: 200px;
  }
  header #search-site .d-block form-control mr-sm-2 mr-auto {
    height: 24px;
  }

  header .wrap-suggestion {
    top: 35px;
  }
}
